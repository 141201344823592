@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap");
/* body {
  font-family: "Inter", sans-serif !important;
  font-size: 32px !important;
} */

.app {
  /* min-height: 100vh;
  padding-top: 50px; */
  background-image: url(../images/pattern-curve.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: auto auto;
}

.quote__container {
  height: 350px;
  width: 625px;
  font-weight: 200;
  background-image: url(../images/pattern-quotes.svg);
  background-repeat: no-repeat;
  background-position-x: 93px;
  padding: 68px 0 0 0;
  line-height: 45px;
  position: relative;
  /* top: 190px; */
  left: 122px;
  color: hsl(240, 38%, 20%);
  z-index: 2;
}
.quote__container--description {
  font-weight: 300;
  font-size: 20px;
  
}
.quote__cite {
  font-size: 18px;
  letter-spacing: 0.8px;
  margin-top: 10px;
}

.quote__cite--name {
  margin-right: 8px;
  font-weight: 700;
  color: hsl(240, 38%, 20%);
}
.quote__cite--profession {
  font-weight: 500;
  color: hsl(240, 18%, 77%);
  margin-left: 10px;
}

.image__container {
  background-image: url(../images/pattern-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 700px;
  padding: 85px 80px 60px 60px;
}
.image__container img {
  border-radius: 100px;
  -webkit-box-shadow: 0px 23px 34px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 23px 34px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 23px 34px 0px rgba(0, 0, 0, 0.18);
  width: 150px;
  height: 150px;
  margin-left: 15%;
}

.navigation__buttons {
  /* height: 60px;
  width: 115px; */
  height: 35px;
  width: 70px;
  border-radius: 30px;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -30px;
  /* top: -40px; */
  /* left: 150px; */
  left: 125px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.prev__btn {
  background-image: url(../images/icon-prev.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 30px 0 0 30px;
  background-color: white;
}

.next__btn {
  background-image: url(../images/icon-next.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0 30px 30px 0;
  background-color: white;
}
.prev__btn:hover,
.next__btn:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.attribution {
  font-size: 15px;
}

/**ANIMACIONES**/
.animationText {
  transition: all 1s ease-out;
  animation: animationInfo 0.4s;
}
@keyframes animationInfo {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.animationImg {
  transition: all 1s ease-out;
  animation: animationImages 0.4s;
}

@keyframes animationImages {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media only screen and (max-width: 1325px) {
  .app {
    width: 100vw;
    padding: 0px;
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 60px;
    align-content: start;
    justify-content: center;
    justify-items: center;
    background-attachment: fixed;
  }
  .quote__container {
    background-position-x: 50%;
    text-align: center;
    position: static;
    top: 0;
    left: 0;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .quote__cite--name {
    display: grid;
    grid-template-rows: auto auto;
  }
  .quote__cite--name,
  .quote__cite--profession {
    height: 28px;
  }
  .image__container {
    background-size: contain;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    justify-content: center;
  }
  .navigation__buttons {
    left: 40%;
    top: -30px;
  }
}
@media only screen and (max-width: 740px) {
  .app {
    grid-row-gap: 20px;
  }
  .quote__container,
  .image__container {
    width: 335px;
    height: 335px;
  }
  .image__container {
    margin-top: 20px;
    padding: 40px 36px 40px 30px;
  }

  .navigation__buttons {
    /* width: 100px;
    height: 50px;
    left: 90px;
    top: -25px; */
    width: 75px;
    height: 35px;
    left: 60px;
    top: -55px;
  }
  .quote__container {
    background-size: 20%;
    padding: 28px 15px 0 15px;
    font-size: 18px;
    line-height: 28px;
  }
  
  .attribution {
    text-align: center;
  }
}


@media only screen and (max-width: 992px) and (max-width: 767px) {
  .quote__container--description {
    font-weight: 300;
    font-size: 15px;
   
  }
  .image__container img {
    width: 150px;
    height: 150px;
  }
}
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap);
@font-face {
  font-family: 'GothamBookRegular';
  src: local('GothamBookRegular'), url(/static/media/GothamBookRegular.a563635d.otf) format('truetype');
}


@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'), url(/static/media/Lato.0b443baa.ttf) format('truetype');
  font-weight: 500;

}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis.ttf'), url(/static/media/Metropolis-Medium.a734a76e.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Metropolis-Black';
  src: local('Metropolis.ttf'), url(/static/media/Metropolis-Black.fa33a551.ttf) format('truetype');
  font-weight: 1100;
}


@font-face {
  font-family: 'Metropolis-Bold';
  src: local('Metropolis.ttf'), url(/static/media/Metropolis-Bold.0545ca64.ttf) format('truetype');
  font-weight: 900;
}


@font-face {
  font-family: 'DMSerifDisplay';
  src: local('DM Serif Display'), url(/static/media/DMSerifDisplay-Regular.359da220.ttf) format('truetype');
}





@font-face {
  font-family: 'GothamThin';
  src: local('GothamThin'), url(/static/media/GothamThin.124f233c.otf) format('truetype');
}

@font-face {
  font-family: 'UniformBlack';
  src: local('UniformBlack'), url(/static/media/UniformBlack.365c2b9d.ttf) format('truetype');
}

@font-face {
  font-family: 'UniformMedium';
  src: local('UniformMedium'), url(/static/media/UniformMedium.3c010699.ttf) format('truetype');
}

@font-face {
  font-family: 'UniformMedium', serif;
  src: local('UniformMediumSerif'), url(/static/media/UniformMedium.3c010699.ttf) format('truetype');
}

@font-face {
  font-family: 'Playfair';
  src: local('PlayfairDisplay'), url(/static/media/PlayfairDisplay.20e1565d.ttf) format('truetype');
}

@font-face {
  font-family: "Georgia", serif;
  src: local('georgiabSerif'), url(/static/media/Georgia.a9f9eef0.ttf) format('truetype');
}

@font-face {
  font-family: "Georgia";
  src: local('Georgia'), url(/static/media/Georgia.a9f9eef0.ttf) format('truetype');
}

@font-face {
  font-family: "Geneva";
  src: local('Geneva'), url(/static/media/Geneva.5ef60dc8.ttf) format('truetype');
}

/* @font-face {
  font-family: "Georgia", "Cambria", "Times New Roman", "Times", sans-serif;
  src: local('georgiaSans'), url(./Fonts/georgia.ttf) format('truetype');
}

@font-face {
  font-family: "Georgia";
  src: local('georgiabRegular'), url(./Fonts/georgia.ttf) format('truetype');
} */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body, html { */
  /* for video lightbox, this removes digest list scroll */
  /* overflow: auto; 
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



  
/* body {
  font-family: "Inter", sans-serif !important;
  font-size: 32px !important;
} */

.app {
  /* min-height: 100vh;
  padding-top: 50px; */
  background-image: url(/static/media/pattern-curve.ae8c0639.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: auto auto;
}

.quote__container {
  height: 350px;
  width: 625px;
  font-weight: 200;
  background-image: url(/static/media/pattern-quotes.83ba950c.svg);
  background-repeat: no-repeat;
  background-position-x: 93px;
  padding: 68px 0 0 0;
  line-height: 45px;
  position: relative;
  /* top: 190px; */
  left: 122px;
  color: hsl(240, 38%, 20%);
  z-index: 2;
}
.quote__container--description {
  font-weight: 300;
  font-size: 20px;
  
}
.quote__cite {
  font-size: 18px;
  letter-spacing: 0.8px;
  margin-top: 10px;
}

.quote__cite--name {
  margin-right: 8px;
  font-weight: 700;
  color: hsl(240, 38%, 20%);
}
.quote__cite--profession {
  font-weight: 500;
  color: hsl(240, 18%, 77%);
  margin-left: 10px;
}

.image__container {
  background-image: url(/static/media/pattern-bg.bb83e5bd.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 700px;
  padding: 85px 80px 60px 60px;
}
.image__container img {
  border-radius: 100px;
  box-shadow: 0px 23px 34px 0px rgba(0, 0, 0, 0.18);
  width: 150px;
  height: 150px;
  margin-left: 15%;
}

.navigation__buttons {
  /* height: 60px;
  width: 115px; */
  height: 35px;
  width: 70px;
  border-radius: 30px;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -30px;
  /* top: -40px; */
  /* left: 150px; */
  left: 125px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.prev__btn {
  background-image: url(/static/media/icon-prev.0fa4e8f2.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 30px 0 0 30px;
  background-color: white;
}

.next__btn {
  background-image: url(/static/media/icon-next.5421c364.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0 30px 30px 0;
  background-color: white;
}
.prev__btn:hover,
.next__btn:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.attribution {
  font-size: 15px;
}

/**ANIMACIONES**/
.animationText {
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
  -webkit-animation: animationInfo 0.4s;
          animation: animationInfo 0.4s;
}
@-webkit-keyframes animationInfo {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes animationInfo {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.animationImg {
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
  -webkit-animation: animationImages 0.4s;
          animation: animationImages 0.4s;
}

@-webkit-keyframes animationImages {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes animationImages {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@media only screen and (max-width: 1325px) {
  .app {
    width: 100vw;
    padding: 0px;
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 60px;
    align-content: start;
    justify-content: center;
    justify-items: center;
    background-attachment: fixed;
  }
  .quote__container {
    background-position-x: 50%;
    text-align: center;
    position: static;
    top: 0;
    left: 0;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .quote__cite--name {
    display: grid;
    grid-template-rows: auto auto;
  }
  .quote__cite--name,
  .quote__cite--profession {
    height: 28px;
  }
  .image__container {
    background-size: contain;
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    justify-content: center;
  }
  .navigation__buttons {
    left: 40%;
    top: -30px;
  }
}
@media only screen and (max-width: 740px) {
  .app {
    grid-row-gap: 20px;
  }
  .quote__container,
  .image__container {
    width: 335px;
    height: 335px;
  }
  .image__container {
    margin-top: 20px;
    padding: 40px 36px 40px 30px;
  }

  .navigation__buttons {
    /* width: 100px;
    height: 50px;
    left: 90px;
    top: -25px; */
    width: 75px;
    height: 35px;
    left: 60px;
    top: -55px;
  }
  .quote__container {
    background-size: 20%;
    padding: 28px 15px 0 15px;
    font-size: 18px;
    line-height: 28px;
  }
  
  .attribution {
    text-align: center;
  }
}


@media only screen and (max-width: 992px) and (max-width: 767px) {
  .quote__container--description {
    font-weight: 300;
    font-size: 15px;
   
  }
  .image__container img {
    width: 150px;
    height: 150px;
  }
}

@font-face {
  font-family: 'GothamBookRegular';
  src: local('GothamBookRegular'), url(./Fonts/GothamBookRegular.otf) format('truetype');
}


@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'), url(./Fonts/Lato.ttf) format('truetype');
  font-weight: 500;

}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis.ttf'), url(./Fonts/Metropolis-Medium.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Metropolis-Black';
  src: local('Metropolis.ttf'), url(./Fonts/Metropolis-Black.ttf) format('truetype');
  font-weight: 1100;
}


@font-face {
  font-family: 'Metropolis-Bold';
  src: local('Metropolis.ttf'), url(./Fonts/Metropolis-Bold.ttf) format('truetype');
  font-weight: 900;
}


@font-face {
  font-family: 'DMSerifDisplay';
  src: local('DM Serif Display'), url(./Fonts/DMSerifDisplay-Regular.ttf) format('truetype');
}





@font-face {
  font-family: 'GothamThin';
  src: local('GothamThin'), url(./Fonts/GothamThin.otf) format('truetype');
}

@font-face {
  font-family: 'UniformBlack';
  src: local('UniformBlack'), url(./Fonts/UniformBlack.ttf) format('truetype');
}

@font-face {
  font-family: 'UniformMedium';
  src: local('UniformMedium'), url(./Fonts/UniformMedium.ttf) format('truetype');
}

@font-face {
  font-family: 'UniformMedium', serif;
  src: local('UniformMediumSerif'), url(./Fonts/UniformMedium.ttf) format('truetype');
}

@font-face {
  font-family: 'Playfair';
  src: local('PlayfairDisplay'), url(./Fonts/PlayfairDisplay.ttf) format('truetype');
}

@font-face {
  font-family: "Georgia", serif;
  src: local('georgiabSerif'), url(./Fonts/Georgia.ttf) format('truetype');
}

@font-face {
  font-family: "Georgia";
  src: local('Georgia'), url(./Fonts/Georgia.ttf) format('truetype');
}

@font-face {
  font-family: "Geneva";
  src: local('Geneva'), url(./Fonts/Geneva.ttf) format('truetype');
}

/* @font-face {
  font-family: "Georgia", "Cambria", "Times New Roman", "Times", sans-serif;
  src: local('georgiaSans'), url(./Fonts/georgia.ttf) format('truetype');
}

@font-face {
  font-family: "Georgia";
  src: local('georgiabRegular'), url(./Fonts/georgia.ttf) format('truetype');
} */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body, html { */
  /* for video lightbox, this removes digest list scroll */
  /* overflow: auto; 
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}